import React from "react";
import Header from "../src/components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
// import Services from "./components/services/Services";
// import Portfolio from "./components/portfolio/Portfolio";
// import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Slider from "./components/Slider";
import "./index.css";
export default function App() {
  return (
    <div className="white font-body leading-[1.7rem] text-white ">
      <Header />
      <Nav />
      <About />
      <Experience />
      <Slider />
      <Contact />
      <Footer />
    </div>
  );
}


// https://airtable.com/appWKfo6vEZ3oLBOk/tblFsIT6xhhbd6mwL/viwDUG64jVZqYksAr/recRffs3sLD5XuAmo/fldWP4HlGZKVIV2AS/attHZiRbp6Wd1Hp2o?blocks=hide