import React from "react";
import setting from "../../../src/assets/setting.svg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section
      id="about"
      className="flex justify-center items-center text-center"
    >
      <div className="about mx-auto flex flex-col items-center text-center w-10/12 pt-2 md:pt-9">
        <h5 className="font-medium text-sm text-light">Get To know More</h5>
        <h2 className="text-primary text-3xl mb-[3rem]">About Me</h2>
        <div className="flex items-center mb-3  justify-center flex-col lg:flex-row lg:gap-14 ">
          <div className="imgDiv mb-[3rem] meBgImg rounded-[2rem] aspect-square w-4/5 md:w-2/5 lg:w-1/3">
            <div className="imageBg meBgImg1  overflow-hidden rounded-[2rem] md:transform md:transition md:rotate-12 hover:rotate-0">
              <img src={setting} alt="about" />
            </div>
          </div>
          <div className="content flex flex-col items-center lg:items-start lg:w-2/3">
            <div className="cards flex flex-wrap flex-row md:text-left justify-center items-center gap-5">
              <article className="about__card sm:w-[46%] 2xl:justify-center md:w-48 lg:hover:bg-navBg lg:hover:border hover:border-primaryVariant border border-transparent hover:cursor-pointer  bg-bgVariant flex flex-col transition  items-center p-[2rem] rounded-[1.5rem]">
                <FaAward className="text-primary text-base 2xl:text-5xl mb-[1rem] " />
                <h5 className="font-medium text-sm ">
                  Experience
                </h5>
                <small className="font-normal text-[0.7rem] text-light">
                  2+ Year Learning
                </small>
              </article>
              <article className="about__card  sm:w-[46%] 2xl:justify-center md:w-48 lg:hover:bg-navBg lg:hover:border hover:border-primaryVariant border border-transparent hover:cursor-pointer  bg-bgVariant flex flex-col transition  items-center p-[2rem] rounded-[1.5rem]">
                <FiUsers className="text-primary text-base 2xl:text-5xl mb-[1rem]" />
                <h5 className="font-medium text-sm ">Clients</h5>
                <small className="font-normal text-[0.7rem] text-light">
                  3+
                </small>
              </article>
              <article className="about__card  sm:w-[46%] 2xl:justify-center md:w-48 lg:hover:bg-navBg hover:border hover:border-primaryVariant border border-transparent hover:cursor-pointer  bg-bgVariant flex flex-col transition  items-center p-[2rem] rounded-[1.5rem]">
                <VscFolderLibrary className="text-primary text-base 2xl:text-5xl mb-[1rem]" />
                <h5 className="font-medium  text-sm ">Projects</h5>
                <small className="font-normal text-[0.7rem] text-light">
                  10+ Complited
                </small>
              </article>
            </div>

            <p className="my-[1.5rem] break-all  text-center md:mt-[2rem] md:mb-[2.6rem] lg:text-left">
              Hi I'm Mays <br></br> 
              I am a frontend developer. I am known for my curiosity and love for learning. I am most passionat about creating websites and solving the problems that faces me.


            </p>
            <a
              href="#contact"
              className="w-max items-left py-[0.75rem] px-[1.2rem] hover:bg-white text-bg  bg-primary hover:border-none  rounded-lg cursor-pointer  inline-block "
            >
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
