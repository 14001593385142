import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
const HEaderSocials = () => {
  return (
    <div className="header__socials text-xl text-primary hidden absolute flex-col algin-center spacy-y-[0.8] left-0 bottom-[3rem] lg:bottom-[8rem] md:flex">
      <a
        href="https://www.linkedin.com/in/mayce-al-azzawi-58912a1a8/"
        rel="noreferrer"
        target="_blank"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/MayceAlazzawi"
        rel="noreferrer"
        target="_blank"
      >
        <FaGithub />
      </a>
      <a
        href="http://https://www.instagram.com/mys_alazzawi/"
        rel="noreferrer"
        target="_blank"
      >
        <AiFillInstagram />
      </a>
    </div>
  );
};

export default HEaderSocials;
