import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
const Experience = () => {
  return (
    <section className="experience" id="experience">
      <div className=" text-center w-11/12 mx-auto  flex  md:mt-0 flex-col gap-5 lg:pt-9">
        <div className="text mb-4">
          <h5 className="font-medium mb-0 pb-0 text-sm text-light">
            What Skills I Have?
          </h5>
          <h2 className="text-primary text-3xl">My Experience</h2>
        </div>
        <div className="content flex md:justify-center ">
          <div className="Frontend-skills  justify-center lg:hover:border-primaryVariant border border-transparent  lg:hover:bg-navBg transition bg-bgVariant !hover:border !hover:border-primaryVariant hover:pointer-cursor p-[2rem] flex  flex-wrap  gap-1 rounded-[1.5rem] md:justify-center md:w-10/12">
            <h3 className="frontend text-primary flex mb-2 text-center font-medium text-lg">
              Frontend Development
            </h3>
            <div className="skills flex flex-wrap justify-between items-center md:justify-center md:flex-row gap-2">
              <div className="skill flex m-2  w-1/3 md:w-min  flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className="mt-1.5 text-primary" />
                  <h5 className="Html mx-1">HTML</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Experienced
                </small>
              </div>
              <div className="skill flex m-2  w-1/3 md:w-min  flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className=" mt-1.5 text-primary" />
                  <h5 className="Html mx-1">CSS</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Experienced
                </small>
              </div>
              <div className="skill flex m-2  w-1/3 md:w-min  flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className=" mt-1.5 text-primary" />
                  <h5 className="Html mx-1">React</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>
              <div className="skill flex m-2  w-1/3 md:w-min flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className="mt-1.5 text-primary" />
                  <h5 className="Html mx-1">Next.js</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>
              <div className="skill flex m-2  w-1/3 md:w-min flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className=" mt-1.5 text-primary" />
                  <h5 className="Html mx-1">Tailwind</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>
              <div className="skill flex m-2  w-1/3 md:w-min flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className=" mt-1.5 text-primary" />
                  <h5 className="Html mx-1">Git</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>
              <div className="skill flex m-2  w-1/3 md:w-min flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className="mt-1.5 text-primary" />
                  <h5 className="Html mx-1">GitHub</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>
              <div className="skill flex m-2  w-1/3 md:w-min flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className="mt-1.5 text-primary" />
                  <h5 className="Html mx-1">i18next</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>
              <div className="skill flex m-2 w-2/3 md:w-min flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className=" mt-1.5 text-primary" />
                  <h5 className="Html mx-1">Bootstrap</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Experienced
                </small>
              </div>
              <div className="skill flex m-2  w-2/3 md:w-min  flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className=" mt-1.5 text-primary" />
                  <h5 className="Html mx-1">Javascript</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>{" "}
              <div className="skill flex m-2  w-2/3 md:w-min  flex-col">
                <div className="one flex-row flex items-start justify-start ">
                  <BsPatchCheckFill className=" mt-1.5 text-primary" />
                  <h5 className="Html mx-1">Typescript</h5>
                </div>
                <small className="font-normal text-left ml-4 text-[0.7rem] text-light">
                  Intermediat
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
