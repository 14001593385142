import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
// import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";
import { useState } from "react";

const Nav = () => {
  const [active, setActive] = useState("#home");
  // // When the user scrolls the page, execute myFunction

  return (
    // <div className="flex align-center w-full justify-center">
    <nav
      id="navbar"
      className="bg-navBg z-10 -translate-x-2/4 fixed left-2/4 inset-x-1/4  w-max  px-[1.7rem]  py-[0.7rem] z-3 backdrop-blur-sm backdrop-filter flex bottom-[2rem] space-x-[0.8rem]  rounded-full text-center "
    >
      <a
        href="#home"
        className={
          active === "#home"
            ? "active text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
            : "text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
        }
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        onClick={() => setActive("#about")}
        className={
          active === "#about"
            ? "active text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
            : "text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
        }
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        onClick={() => setActive("#experience")}
        className={
          active === "#experience"
            ? "active text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
            : "text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
        }
      >
        <BiBook />
      </a>
      {/* <a
          href="#service"
          onClick={() => setActive("#service")}
          className={
            active === "#service"
              ? "active text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
              : "text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
          }
        >
          <RiServiceLine />
        </a> */}
      <a
        href="#contact"
        onClick={() => setActive("#contact")}
        className={
          active === "#contact"
            ? "active text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
            : "text-light rounded-full  text-sm p-[0.8rem] hover:bg-navBg"
        }
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
    // </div>
  );
};

export default Nav;
