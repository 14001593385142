import React from "react";
import MaysResume2023 from "../../assets/workshops/MaysResume2023.pdf";
const CTA = () => {
  return (
    <div className="cta mt-8 mb-2 md:mb-3 flex space-x-[1.2rem] justify-center">
      <a
        href={MaysResume2023}
        download
        className="w-max text-primary hover:bg-primaryVariant hover:text-bg-1 hover:border-transparent rounded-lg borderr py-[0.75rem] px-[1.2rem] cursor-pointer inline-block"
      >
        Download CV
      </a>
      <a
        href="#contact"
        className="w-max py-[0.75rem] px-[1.2rem] hover:bg-white text-bg  bg-primary hover:border-none  rounded-lg cursor-pointer  inline-block "
      >
        Let's Talk
      </a>
    </div>
  );
};

export default CTA;
