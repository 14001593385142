import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  const [sent, setSent] = useState();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_69u1mmp",
        "template_kzf2djm",
        form.current,
        "lhkVlZT7AUgnGjGUk"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSent(true);
        },
        (error) => {
          console.log(error.text);
          setSent(false);
        }
      );
    document.getElementById("form").reset(); //Why it is not being reseted
    console.log("hh");
  };
  return (
    <section id="contact">
      <div className="text-center flex justify-center flex-col items-center mx-auto w-11/12 gap-8">
        <div className="">
          <h5 className="font-medium text-sm text-light">Get In Touch</h5>
          <h2 className="text-primary text-3xl">Contact Me</h2>
        </div>
        <div className="contact_container flex flex-col w-full lg:w-6/12 gap-6 sm:flex-row sm:justify-center">
          <div className="contact-options w-full gap-3 flex flex-col items-center sm:w-[25%]">
            <article className="contact-card w-full px-3 py-4 lg:hover:bg-navBg bg-bgVariant hover:border-primaryVariant border border-transparent flex flex-col transition  items-center rounded-[1.5rem]">
              <MdOutlineEmail className="text-primary  text-base  mb-[1rem]"/>
              <h4 className="font-medium text-sm ">Email</h4>
              <h5 className="font-normal  text-[0.7rem] text-light">
                Maynab4@gmail.com
              </h5>
              <a
                href="mailto:maynab4@gmail.com"
                className="text-primary font-light text-xs hover:cursor-pointer"
                rel="noreferrer"
                target="_blank"
              >
                Send a message
              </a>
            </article>
            <article className="contact-card w-full px-3 py-4  flex flex-col transition bg-bgVariant lg:hover:bg-navBg hover:border hover:border-primaryVariant border border-transparent items-center p-[1.5rem] rounded-[1.5rem]">
              <BsWhatsapp className="text-primary  text-base  mb-[1rem] " />
              <h4 className="font-medium text-sm ">Whatsapp</h4>
              <h5 className="font-normal  text-[0.7rem] text-light">
                +90 (535)225 14 01
              </h5>
              <a
                href="https:/wa.me/00905352251401"
                className="text-primary font-light text-xs hover:cursor-pointer "
                rel="noreferrer"
                target="_blank"
              >
                Send a message
              </a>
            </article>
            <article className="contact-card w-full px-3 py-4   bg-bgVariant flex flex-col transition lg:hover:bg-navBg hover:border hover:border-primaryVariant border border-transparent items-center p-[1.5rem] rounded-[1.5rem]">
              <RiMessengerLine className="text-primary  text-base  mb-[1rem] " />
              <h4 className="font-medium  text-sm ">Messenger</h4>
              <h5 className="font-normal  text-[0.7rem] text-light">
                Mys_Alazzawi
              </h5>
              <a
                href="https://m.me/mayce.alazawy.3/"
                className="text-primary font-light text-xs hover:cursor-pointer "
                target="_blank"
                rel="noreferrer"
              >
                Send a message
              </a>
            </article>
          </div>
          <div className="form w-full lg:w-[75%]">
            <form
              id="form"
              ref={form}
              onSubmit={sendEmail}
              className="w-full"
            >
              <div className="flex flex-wrap  mb-2">
                <div className="w-full ">
                  <input
                    className=" block w-full bg-transparent text-light border !border-primaryVariant rounded py-4 px-5 mb-3 leading-tight focus:outline-none focus:bg-navBg !focus:border-primaryVariant"
                    id="nick"
                    name="name"
                    type="text"
                    placeholder="Your Full Name"
                  />
                </div>
              </div>
              <div className="flex flex-wrap  mb-2">
                <div className="w-full ">
                  <input
                    className=" block w-full bg-transparent text-light border !border-primaryVariant rounded py-4 px-5 mb-3 leading-tight focus:outline-none focus:bg-navBg focus:border-primaryVariant"
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Your E-mail"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-1">
                <div className="w-full">
                  <textarea
                    name="message"
                    className=" no-resize  block w-full bg-transparent text-light border !border-primaryVariant rounded py-4 px-5 mb-3 leading-tight focus:outline-none focus:bg-navBg focus:border-primaryVariant h-48 resize-none"
                    id="message"
                    placeholder="Your Message"
                  ></textarea>
                </div>
              </div>
              <div className="flex flex-row justify-center items-center gap-2 items-end bottom-0 sm:justify-start">
                <input
                  href="#contact"
                  type="submit"
                  onClick={sendEmail}
                  value="Send Message"
                  className="w-max lg:items-left py-[0.75rem] px-[1.2rem] hover:bg-white text-bg  bg-primary hover:border-none  rounded-lg cursor-pointer  inline-block "
                />
              </div>
              {sent === true ? (
                <p className="text-base text-white text-left py-2">
                  Thanks for reaching out! I will reply shortly.
                </p>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
